import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import vuetify from '@/plugins/vuetify'
import '@/plugins'

import VueGtag from 'vue-gtag'
import store from '@/store'

Vue.config.productionTip = false

Vue.mixin({

  data () {
    return {
      loadedGtag: false,

      language_id: {
        en: 1,
        1: 'en',
        id: 2,
        2: 'id',
      },

      corpLinks: {
        Archipelago: 'https://www.archipelagohotels.com/en',
        ASTON: 'https://pba.archipelagohotels.com/',
      },

      socialButtons: {
        facebook: { icon: 'mdi-facebook', color: 'indigo' },
        twitter: { icon: 'mdi-twitter', color: 'blue' },
        google: { icon: 'mdi-google-plus', color: 'deep-orange' },
        instagram: { icon: 'mdi-instagram', color: 'red accent-3' },
        youtube: { icon: 'mdi-youtube', color: 'red' },
        tiktok: { icon: 'mdi-music-note-outline', color: 'black' },
        linkedin: { icon: 'mdi-linkedin', color: 'blue' },
      },
    }
  },
  computed: {
    isMobile () { return this.$vuetify.breakpoint.mdAndDown },
    isSplashOffers () { return this.$store && this.$store.getters.isSplashOffers },

    website_data () { return this.$store && this.$store.getters.website_data },
    label_data () { return this.$store && this.$store.getters.label_data },
    roomrate_data () { return this.$store && this.$store.getters.roomrate_data },
    utm_data () { return this.$store && this.$store.getters.utm_data },

    cssVars () {
      return {
        '--color-primary': this.website_data.profile.primary_color,
        '--color-secondary': this.website_data.profile.secondary_color,
        '--color-primary-rgb': this.convertHex(this.website_data.profile.primary_color),
        '--color-secondary-rgb': this.convertHex(this.website_data.profile.secondary_color),
      }
    },
  },

  watch: {
    website_data () {
      if (!this.loadedGtag && this.website_data.profile?.gtag_id) {
        this.loadedGtag = true
        Vue.use(VueGtag, { config: { id: this.website_data.profile.gtag_id } }, router)
      }
    }
  },

  methods: {
    setSplashOffers() { store.dispatch('setSplashOffers') },

    convertHex (color) {
      color = color.replace('#', '')
      const r = parseInt(color.substring(0, 2), 16)
      const g = parseInt(color.substring(2, 4), 16)
      const b = parseInt(color.substring(4, 6), 16)
      return [r, g, b].join()
    },

    toQueryUrl(url, queries) {
      url = new URL(url)
      Object.entries(queries).forEach(([k, v]) => { url.searchParams.set(k, v) })
      return url.href
    },

    gtagViewEvent(title) {
      this.$gtag?.event('page_view', {
        page_title: title,
      })
    },

    gtagClickEvent(category, label) {
      this.$gtag?.event('click', {
        event_category: category,
        event_label: [category, label].join(' '),
      })
    }
  },
})

Vue.component('ProgressCircular', () => import('@/components/ProgressCircular'))

new Vue({
  router,
  vuetify,
  store,
  beforeCreate: () => store.dispatch('init'),
  render: h => h(App),
}).$mount('#app')

<template>
  <v-fade-transition mode="out-in">
    <router-view v-if="website_data && label_data && roomrate_data" :style="cssVars" />
    <div v-else class="loading">
      <v-progress-circular :size="64" indeterminate color="pba-primary" />
    </div>
  </v-fade-transition>
</template>

<script>
  export default {
    name: 'App',
    metaInfo () {
      if (this.website_data) {
        return {
          title: this.website_data.profile.m_title,
          htmlAttrs: { lang: 'en' },
          meta: [
            { charset: 'utf-8' },
            { name: 'viewport', content: 'width=device-width, initial-scale=1' },
            { name: 'description', content: this.website_data.profile.m_description },
            { name: 'og:image', content: this.website_data.profile.thumbnail_desktop },
          ],
        }
      }
    },

    // Watch on Website Data Load
    watch: {
      website_data () { this.website_data && (document.getElementById('favicon').href = this.website_data.profile.hotel_logo) },
    },

    beforeCreate() { console.log('Updated 240814') },
  }
</script>

<style lang="scss">
  .loading {
    display: flex; justify-content: center; align-items: center;
    height: 100vh;
  }
</style>

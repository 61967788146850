// Imports
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  // base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      component: () => import('@/layouts/Index.vue'),
      children: [
        { path: '', name: 'Home', component: () => import('@/views/Index.vue') },
        { path: 'news', name: 'News', component: () => import('@/views/News.vue') },
        { path: '*', redirect: '/' },
      ],
    },

  ],
})

export default router

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({

  state () {
    return {
      language_id: 1,
      isSplashOffers: true,

      website_data: null,
      label_data: null,
      roomrate_data: null,

      utm_data: {},
    }
  },

  getters: {
    isSplashOffers: state => state.isSplashOffers,
    website_data: state => state.website_data,
    label_data: state => state.label_data,
    roomrate_data: state => state.roomrate_data,
    utm_data: state => state.utm_data
  },

  actions: {
    setSplashOffers({ state, commit }) { commit('SET_SPLASH_OFFERS') },
    async init ({ dispatch }) {
      await Promise.all([
        dispatch('fetch_website_data'),
        dispatch('fetch_label_data'),
        // dispatch('fetch_roomrate_data'),
      ])
    },
    async fetch_website_data ({ state, commit }) {
      const {
        domain, template,
        utm_source, utm_medium, utm_campaign, utm_id, utm_term, utm_content
      } = Object.fromEntries(new URLSearchParams(window.location.search).entries())

      console.log('Loading', domain || process.env.VUE_APP_PBA_API_DOMAIN || window.location.hostname)

      if (utm_source || utm_medium || utm_campaign || utm_id || utm_term || utm_content) {
        commit('SET_UTM', { utm_source, utm_medium, utm_campaign, utm_id, utm_term, utm_content })
      }

      await fetch(
        [
          process.env.VUE_APP_PBA_API_URL,
          'website-data', state.language_id,
          domain || process.env.VUE_APP_PBA_API_DOMAIN || window.location.hostname
        ].join('/'),
        { headers: { Authorization: `Bearer ${ process.env.VUE_APP_PBA_API_TOKEN }` } },
      )
      .then(res => res.json())
      .then(data => {
        if (template) data.profile.template_name = template
        else if (process.env.VUE_APP_PBA_API_TEMPLATE) data.profile.template_name = process.env.VUE_APP_PBA_API_TEMPLATE
        commit('SET_WEBSITE_DATA', data)
        // console.log('WEBSITE', data)

        if (data.profile.hotel_tawkto) {
          Array.from(new DOMParser().parseFromString(data.profile.hotel_tawkto, 'text/html')
          .getElementsByTagName('script'))
          .forEach(s => {
            Function(s.innerHTML)()
          })
        }

        fetch(
          [process.env.VUE_APP_PBA_API_URL, 'roomrate', data.profile.hotel_id].join('/'),
          { headers: { Authorization: `Bearer ${ process.env.VUE_APP_PBA_API_TOKEN }` } },
        )
        .then(res => res.json())
        .then(data => {
          commit('SET_ROOMRATE_DATA', data)
          // console.log('ROOMRATE', data)
        })
        .catch(err => console.error(err.message))
      })
      .catch(err => console.error(err.message))
    },
    async fetch_label_data ({ state, commit }) {
      await fetch(
        [process.env.VUE_APP_PBA_API_URL, 'site/labels', state.language_id].join('/'),
        { headers: { Authorization: `Bearer ${ process.env.VUE_APP_PBA_API_TOKEN }` } },
      )
      .then(res => res.json())
      .then(data => {
        commit('SET_LABEL_DATA', data)
      })
      .catch(err => console.error(err.message))
    },
    // async fetch_roomrate_data ({ state, commit }) {
    //   await fetch(
    //     [process.env.VUE_APP_PBA_API_URL, 'roomrate', state.hotel_id].join('/'),
    //     { headers: { Authorization: `Bearer ${ process.env.VUE_APP_PBA_API_TOKEN }` } },
    //   )
    //   .then(res => res.json())
    //   .then(data => commit('SET_ROOMRATE_DATA', data))
    //   .catch(err => console.error(err.message))
    // },
  },

  mutations: {
    SET_SPLASH_OFFERS: (state) => { state.isSplashOffers = false },
    SET_WEBSITE_DATA: (state, data) => { state.website_data = data },
    SET_LABEL_DATA: (state, data) => { state.label_data = data },
    SET_ROOMRATE_DATA: (state, data) => { state.roomrate_data = data },
    SET_HOTEL_ID: (state, id) => { state.hotel_id = id },
    SET_LANGUAGE_ID: (state, id) => { state.language_id = id },
    SET_UTM: (state, data) => { state.utm_data = Object.fromEntries(Object.entries(data).filter(([_, v]) => v != null)) }
  },

})

export default store
